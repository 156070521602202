// @ts-ignore
import cloneDeep from 'lodash.clonedeep';

export default function separateScriptsAndContent (contentFromServer: Array<TContent | TScript>): {
  content: TContent[],
  scripts: TScript[],
  inlineScripts: TScript[]
} {
  const scripts: TScript[] = [];
  const inlineScripts: TScript[] = [];

  const splitScriptsFromContent = (item: TContent): boolean => {
    if (item.type === 'comment') {
      return false;
      // скрипты, которые нужны только для нативных приложений
    } else if (item?.attributes && item?.attributes['data-type'] === 'only-native-apps') {
      return false;
    } else if (item.type === 'script') {
      item?.attributes?.src
        ? scripts.push(item as TScript)
        : inlineScripts.push(item as TScript);
      return false;
    } else if (item.children) {
      item.children = item.children.filter(splitScriptsFromContent);
    }

    return true;
  };

  const contentFromServerClone = cloneDeep(contentFromServer);
  const content = contentFromServerClone.filter(splitScriptsFromContent);

  return {
    content,
    scripts,
    inlineScripts,
  };
};
