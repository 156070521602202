// TODO: disableBodyScroll, enableBodyScroll запускается только
// если передан terget элемент
// на момент написания коммента - это один случай в файле /src/components/TypoForm.vue
// нужен ли вообще?
// DEPRICATED
// TODO: импортить и использовать из LH-UI
// @ts-ignore
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default class PageScroll {
  private static state: 'auto' | 'hidden' = 'auto';

  private static preventDefault = (e: Event) => {
    e.preventDefault();
    if (PageScroll.scrollPosition !== null) {
      window.scrollTo(0, PageScroll.scrollPosition);
    }
    return false;
  }

  // null нужен чтобы проверять нужно ли скролить когда вызываем enable
  private static scrollPosition: null | number = null;

  private static styles: any = {
    overflow: () => 'hidden',
    top: () => `-${PageScroll.scrollPosition}px`,
    width: () => '100%',
    height: () => '100vh',
    // Для того чтобы не было скачка контента из-за скрытия скроллбара добавляется отступ
    'padding-right': () => `${window.innerWidth - document.body.clientWidth}px`,
  };

  // для iOS Safari
  private static disableTouchScroll () {
    window.addEventListener('scroll', PageScroll.preventDefault, { passive: false });
  }

  // для iOS Safari
  private static enableTouchScroll () {
    window.removeEventListener('scroll', PageScroll.preventDefault);
  }

  static enable (targetElement: Element | null = null, disabledTouchScroll: boolean = true): void {
    if (targetElement) {
      enableBodyScroll(targetElement);
    }
    if (disabledTouchScroll) {
      this.enableTouchScroll();
    }

    PageScroll.state = 'auto';

    Object.keys(this.styles).forEach((styleProperty: string) => {
      document.documentElement.style.removeProperty(styleProperty);
    });

    if (PageScroll.scrollPosition !== null && typeof window.scrollTo === 'function') {
      window.scrollTo(0, this.scrollPosition as number);
      this.scrollPosition = null;
    }
  }

  static disable (targetElement: Element | null = null, disableTouchScroll: boolean = true): void {
    if (targetElement) {
      disableBodyScroll(targetElement);
    }

    if (disableTouchScroll) {
      this.disableTouchScroll();
    }

    if (this.state !== 'hidden') {
      this.scrollPosition = window.pageYOffset;
    }

    PageScroll.state = 'hidden';
    Object.keys(this.styles).forEach((key: any) => {
      document.documentElement.style[key] = this.styles[key]();
    });
  }
}
