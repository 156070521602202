// TODO: Попробовать реализовать скролл без добавления классов заголовкам
// Нормализация данных статьи с шаблоном "карточки".

// Добавление ид элементам меню.
// Добавление классов заголовкам карточек.
// Это нужно для скролла к этому элементу при клике на элементы меню.

// @ts-ignore
import cloneDeep from 'lodash.clonedeep';

import { isArray } from '~/utils';

const addClassesToCardTitle = (content: Array<TContent>): Array<TContent> => {
  let index = 0;
  return content.map((item) => {
    if (item?.attributes?.class?.includes('lh-card')) {
      let found = false;
      item.children = item?.children?.map((childrenItem) => {
        if (childrenItem.type === 'h2' && !found) {
          const newChildrenItem = {
            ...childrenItem,
            attributes: {
              ...childrenItem.attributes,
              class: childrenItem?.attributes?.class ?? '' + ` lh-card__title lh-card__title-${index}`,
            },
          };
          index = index + 1;
          found = true;
          return newChildrenItem;
        } else {
          return childrenItem;
        }
      });
    }
    return item;
  });
};

type MenuItem = {
  id: symbol,
  value: string
};
const addIdToMenuItems = (menu: Array<string>): Array<MenuItem> =>
  menu.map((value: string): MenuItem => ({
    value,
    // eslint-disable-next-line symbol-description
    id: Symbol(),
  }));

export const normalizeTemplateCardData = (article: unknown): ISingleArticle => {
  const newArticle = cloneDeep(article);

  newArticle.content = addClassesToCardTitle(newArticle.content);

  if (isArray(newArticle?.meta?.card?.menuItems)) {
    newArticle.meta.card.menuItems = addIdToMenuItems(newArticle.meta.card.menuItems);
  }

  return newArticle;
};
